<template>
    <div class="annuaire__view">
        <div class="header">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher un article du site" single-line hide-details></v-text-field>
        </div>
        <div class="control">
            <v-btn v-if="site.user > 0" color="#272727" class="white--text" @click="$router.push({path: '/user/' + site.user})" tile x-large>
                Retour
            </v-btn>
        </div>
        <v-data-table :headers="headers" :items="articlesWP" :loading="loading" :search="search" :footer-props="{
    itemsPerPageOptions: [10,20,30]
  }" :sort-by="['date']" :sort-desc="[true]" class="elevation-1">
            <template v-slot:item.date="{ item }">
                {{ formatedDate(item.date) }}
            </template>
            <template v-slot:item.title.rendered="{ item }">
                <span v-html="item.title.rendered"></span>
            </template>
            <template v-slot:item.content.rendered="{ item }">
                <p v-html="item.content.rendered"></p>
            </template>
            <template v-slot:item.actions="{ item }">
                <a :href="item.link" target="_blank" title="Lien vers l'article">
                    <v-icon class="mr-2">
                        mdi-link
                    </v-icon>
                </a>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import moment from 'moment'

export default {
    name: 'Annuaire',
    data() {
        return {
            articlesWP: [],
            site: {
                id: 0,
                user: 0
            },
            uploadCSV: null,
            loading: true,
            loadingAdd: false,
            loadingAddCsv: false,
            search: '',
            options: {
                page: 1,
                sortBy: ["created"],
                itemsPerPage: 15,
                sortDesc: [true],
            },
            headers: [
                { text: 'Création', value: 'date' },
                { text: 'Titre', value: 'title.rendered' },
                { text: 'Contenu', value: 'content.rendered' },
                { text: '', value: 'actions', sortable: false }
            ],
        }
    },
    watch: {
        search: {
            handler() {
                this.loadData()
            }
        },
        options: {
            handler() {
                this.loadData()
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions("global", [
            "getSiteAction",
            "getSiteArticlesWpAction"
        ]),
        loadData() {
            var _this = this;
            this.loading = true;
            this.getSiteArticlesWpAction(this.$route.params.siteId)
            .then(res => {
                _this.articlesWP = res.data
            })
            .finally(() => {
                _this.loading = false
            })
        },
        formatedDate(date) {
            return moment(date).format("DD/MM/YYYY à HH:mm")
        },
        loadSite() {
            const _this = this
            this.getSiteAction(this.$route.params.siteId)
            .then(function (res) {
                _this.site.id = res.data.id
                _this.site.user = res.data.user
            })
        }
    },
    created() {
        this.loadSite()
        this.loadData()
    }
}
</script>
<style lang="scss" scoped>
.annuaire__view {
    width: 80%;
    margin: 20px auto;
    .header {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .v-input {
            margin: 0;
            padding: 0;
        }
        .v-btn {
            margin: 0 0 0 20px;
        }
    }
    .control {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 100;

        .v-btn {
            width: 50%;
        }
    }
    .flex__adder {
        display: flex;
        gap: 10%;
        form {
            width: 50%;
        }
    }
    form {
        .v-btn {
            margin: 0 0 10px 10px;
        }
        div {
            display: flex;
            justify-content: flex-end;
        }
    }
}
</style>